import React, { useState } from "react";
import Layout from "../layout";

import logos from "../assets/logos";
import checkmark from "../assets/logos/checkmark.svg";
import Github from "../components/github";
import { Helmet } from "react-helmet";

export default ({ uri }) => {
  const [infoOpen, setInfo] = useState(null);

  const skills = [
    {
      name: "Fundamentals",
      id: "base",
      reg: true,
      parts: [
        {
          name: "HTML",
          score: "strong",
          logo: logos.html,
          color: "strong",
          items: [
            "Basic Usage",
            "Advanced Usage",
            "Semantic Tags",
            "Offline & Storage",
            "Multimedia",
            "Device Access",
          ],
        },
        {
          name: "CSS",
          score: "strong",
          logo: logos.css,
          color: "strong",
          items: [
            "Basic Usage",
            "Advanced Usage",
            "Pseudo Elements",
            "Pseudo Classes",
            "z-index & Clipping",
            "Combinators & Media Queries",
            "Animations & Transitions",
          ],
        },
        {
          name: "JS",
          score: "strong",
          logo: logos.js,
          color: "strong",
          items: [
            "Basic Usage",
            "Advanced Usage",
            "Fetch & AJAX requests",
            "Async & Promises",
            "Scope & Error Handling",
            "Classes & Inheritence",
            "ES6 additions..",
          ],
        },
        {
          name: "HTTP",
          score: "strong",
          logo: logos.http,
          color: "strong",
          items: [
            "Basic Usage",
            "Advanced Usage",
            "Requests & Status Codes",
            "Methods & Headers",
            "Authentication & Sessions",
            "Caching & Proxies",
            "REST",
          ],
        },
      ],
    },
    {
      name: "MERN Stack (goto)",
      id: "mern",
      reg: true,
      parts: [
        {
          name: "React",
          score: "strong",
          logo: logos.react,
          color: "strong",
          items: [
            "Basic Usage",
            "Advanced Usage",
            "Hooks",
            "Conditional Rendering",
            "Refs & DOM",
            "ES6 implications",
          ],
        },
        {
          name: "Node",
          score: "good",
          logo: logos.node,
          color: "good",
          items: [
            "Basic Usage",
            "Advanced Usage",
            "Modules & File System",
            "HTTP & Events",
            "NPM & DB Integration",
          ],
        },
        {
          name: "Express",
          score: "good",
          logo: logos.express,
          color: "good",
          items: [
            "Basic Usage",
            "Advanced Usage",
            "Routing & Middleware",
            "Requests & Responses",
            "Templating",
          ],
        },
        {
          name: "MongoDB",
          score: "good",
          logo: logos.mongo,
          color: "good",
          items: [
            "Basic Usage",
            "Create DB & Collection",
            "Insert & Update",
            "Query & Find",
            "Delete & Drop",
            "Limit & Sort",
          ],
        },
      ],
    },
    {
      name: "Django Stack",
      id: "django",
      reg: true,
      parts: [
        {
          name: "Django",
          score: "good",
          logo: logos.django,
          color: "good",
          items: [
            "Basic Usage",
            "Advanced Usage",
            "Models and databases",
            "HTTP requests",
            "Middleware",
            "Templates",
          ],
        },
        {
          name: "DTL",
          score: "good",
          logo: logos.dtl,
          color: "good",
          items: [
            "Basic Usage",
            "Advanced Usage",
            "Template Tags",
            "Template Filters",
            "Conditional Rendering",
          ],
        },
        {
          name: "Python",
          score: "good",
          logo: logos.python,
          color: "good",
          items: [
            "Basic Usage",
            "Modules & PIP",
            "Errors & Exceptions",
            "Classes & Inheritence",
            "File System & Virtual Envs",
          ],
        },

        {
          name: "Nginx",
          score: "basic",
          logo: logos.nginx,
          color: "basic",
          items: ["Basic Usage", "Integrate uWSGI", "Serve App"],
        },
      ],
    },
    {
      name: "LAMP Stack",
      id: "lamp",
      reg: true,
      parts: [
        {
          name: "Linux/Mac",
          score: "good",
          logo: logos.linux,
          color: "good",
          items: ["How it works", "Basic Usage"],
        },
        {
          name: "PHP",
          score: "good",
          logo: logos.php,
          color: "good",
          items: [
            "Basic Usage",
            "File Handling",
            "Form Handling",
            "Cookies & Sessions",
            "Procedural method",
          ],
        },
        {
          name: "Apache",
          score: "basic",
          logo: logos.apache,
          color: "basic",
          items: ["Basic Usage", "Serve App", "Manipulate .htaccess"],
        },
        {
          name: "MySQL",
          score: "basic",
          logo: logos.mysql,
          color: "basic",
          items: [
            "Basic Usage",
            "Create DB & Table",
            "Insert & Update",
            "Query & Where",
            "Delete & Drop",
            "Limit & Order By",
          ],
        },
      ],
    },
    {
      name: "Extras",
      id: "extras",
      reg: false,
      parts: [
        {
          name: "Github",
          score: "good",
          logo: logos.github,
          color: "good",
          items: [
            "Basic Usage",
            "Create Repo",
            "Add & Commit",
            "Push Changes",
            "Clone Repo",
            "Create Branch",
          ],
        },
        // {
        //   name: "Socket.io",
        //   score: "good",
        //   logo: logos.socketio,
        //   color: "good",
        //   items: ["How it works", "Basic Usage"],
        // },
        {
          name: "Bootstrap",
          score: "good",
          logo: logos.bootstrap,
          color: "good",
          items: ["How it works", "Basic Usage"],
        },
        {
          name: "Ionic",
          score: "good",
          logo: logos.ionic,
          color: "good",
          items: ["How it works", "Basic Usage"],
        },
        {
          name: "Illustrator",
          score: "good",
          logo: logos.illustrator,
          color: "good",
          items: ["How it works", "Basic Usage"],
        },
        {
          name: "XD",
          score: "good",
          logo: logos.xd,
          color: "good",
          items: ["How it works", "Basic Usage"],
        },
        {
          name: "Gatsby",
          score: "good",
          logo: logos.gatsby,
          color: "good",
          items: ["How it works", "Basic Usage"],
        },
        {
          name: "Typescript",
          score: "good",
          logo: logos.typescript,
          color: "good",
          items: ["How it works", "Basic Usage"],
        },
        {
          name: "Rust",
          score: "basic",
          logo: logos.rust,
          color: "basic",
          items: ["How it works", "Basic Usage"],
        },
        {
          name: "Jest Testing",
          score: "basic",
          logo: logos.testing,
          color: "basic",
          items: ["How it works", "Basic Usage"],
        },
        {
          name: "Redux",
          score: "basic",
          logo: logos.redux,
          color: "basic",
          items: ["How it works", "Basic Usage"],
        },

        {
          name: "Sass",
          score: "basic",
          logo: logos.sass,
          color: "basic",
          items: ["How it works", "Basic Usage"],
        },
        {
          name: "GraphQL",
          score: "basic",
          logo: logos.graphql,
          color: "basic",
          items: ["How it works", "Basic Usage"],
        },
      ],
    },
  ];
  const toggleInfo = (e, name) => {
    let currentFalse = document.querySelector(".false");
    if (currentFalse) currentFalse.style = null;
    if (infoOpen === name) {
      setInfo(null);
    } else {
      let content = e.currentTarget.nextSibling;
      let supposedHeight = content.scrollHeight;
      content.style.height = supposedHeight + "px";
      setInfo(name);
    }
  };
  return (
    <Layout path={uri}>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>Mohamed's Porfolio &#129305;</title>
        <meta
          name="description"
          content="Full Stack Web Developer Portfolio."
        />
        <meta name="robots" content="nofollow" />
      </Helmet>
      <div className="skills fade">
        <div className="head-info">
          <div className="key">
            <h3>Key:</h3>
            <p>
              <b>Strong</b>: Very comfortable; can build complex applications
              with little to no reliance on documentation.
            </p>
            <p>
              <b>Good</b>: Comfortable; can build complex applications with some
              reliance on documentation.
            </p>
            <p>
              <b>Basic</b>: Anything besides basic functionality requires help
              from documentation.
            </p>
            <br />
          </div>
          <div className="links">
            <a className="normal" href="#base">
              Fundamentals
            </a>
            <a className="normal" href="#mern">
              MERN Stack
            </a>
            <a className="normal" href="#django">
              Django Stack
            </a>
            <a className="normal" href="#lamp">
              LAMP Stack
            </a>
            <a className="normal" href="#extras">
              Extras
            </a>
          </div>
        </div>

        <p>
          <b>PS</b>: Everything listed has been used in *personal* projects.
        </p>
        <br />
        {skills.map((skill, i) => (
          <div key={i} className="skill-container" id={skill.id}>
            <h4 className="skill-title">{skill.name}</h4>
            <div className="skillset">
              {skill.parts.map((part, i) => (
                <div key={i} className="skill">
                  <div className={"percent " + part.color}>{part.score}</div>
                  <div className="skilllogo">
                    <img src={part.logo} alt={part.name} />
                  </div>
                  <div
                    className={`skillinfo ${
                      skill.reg && infoOpen !== part.name ? "active" : null
                    }`}
                  >
                    {skill.reg ? (
                      <>
                        <div
                          className="skillinfo-title"
                          onClick={e => toggleInfo(e, part.name)}
                        >
                          <h5>{part.name}</h5>{" "}
                          <h5 className="details">
                            {infoOpen === part.name ? "\u2013" : "+"}
                          </h5>
                        </div>
                        <div
                          className={`skillinfo-body ${infoOpen !== part.name}`}
                        >
                          {part.items.map((item, i) => (
                            <div className="li" key={i}>
                              <img src={checkmark} className="checkmark" />
                              <p>{item}</p>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <div className="skillinfo-title">
                        <h5>{part.name}</h5>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="future">
          <div>
            <h3>Interested In:</h3>
            <ul>
              <li>WebGL</li>
              <li>Capacitor</li>
              <li>Web Assembly</li>
            </ul>
          </div>
          {/* <div>
            <h3>NOT Interested In:</h3>
            <ul>
              <li></li>
              <li></li>
              <li>Vue</li>
              <li>Ruby</li>
            </ul>
          </div> */}
        </div>
      </div>
      <Github />
    </Layout>
  );
};
