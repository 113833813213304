import React from "react";
import { Link } from "gatsby";
import "../style/index.scss";
import Header from "../components/header";
import Next from "../components/next";
export default ({ path, children }) => {
  return (
    <div className="wrapper">
      <Header path={path} />
      {children}
      <Next path={path} />
    </div>
  );
};
