import apache from "./apache.svg";
import css from "./css.svg";
import django from "./django.svg";
import dtl from "./dtl.svg";
import express from "./express.svg";
import html from "./html.svg";
import js from "./js.svg";
import linux from "./linux.svg";
import mongo from "./mongo.svg";
import nginx from "./nginx.svg";
import mysql from "./mysql.svg";
import node from "./node.svg";
import php from "./php.svg";
import python from "./python.svg";
import react from "./react.svg";
import http from "./http.svg";
import rest from "./rest.svg";
import github from "./github.svg";
import testing from "./testing.svg";
import pwa from "./pwa.svg";
import redux from "./redux.svg";
import socketio from "./socketio.svg";
import sass from "./sass.svg";
import bootstrap from "./bootstrap.svg";
import illustrator from "./illustrator.svg";
import xd from "./xd.svg";
import graphql from "./graphql.svg";
import ionic from "./ionic.svg";
import typescript from "./typescript.svg";
import gatsby from "./gatsby.svg";
import rust from "./rust.svg";

export default {
  apache,
  css,
  django,
  dtl,
  express,
  html,
  js,
  linux,
  mongo,
  nginx,
  mysql,
  node,
  php,
  python,
  react,
  http,
  rest,
  github,
  testing,
  pwa,
  redux,
  socketio,
  graphql,
  sass,
  bootstrap,
  illustrator,
  xd,
  ionic,
  typescript,
  gatsby,
  rust,
};
