import React from "react";
import { Link } from "gatsby";

export default ({ path }) => {
  return (
    <div className="header-container anim">
      <div className="boss-section">
        <div className="left">
          {path === "/" ? null : (
            <div>
              <Link to="/">Home</Link>
            </div>
          )}
          {path === "/about" ? null : (
            <div>
              <Link to="/about">About</Link>
            </div>
          )}
          {path === "/projects" ? null : (
            <div>
              <Link to="/projects">Projects</Link>
            </div>
          )}
          {path === "/skills" ? null : (
            <div>
              <Link to="/skills">Skills</Link>
            </div>
          )}
          {path === "/work" ? null : (
            <div>
              <Link to="/work">Work</Link>
            </div>
          )}
          {path === "/contact" ? null : (
            <div>
              <Link to="/contact">Contact</Link>
            </div>
          )}
        </div>
        <div className="right">
          <div className="boss-text">
            <b>{path.replace("/", "").capitalize()}</b>
          </div>
        </div>
      </div>
    </div>
  );
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
